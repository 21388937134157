export default [
  {
    path: '/tableaux-de-bord/ventes',
    name: 'tableaux-de-bord-ventes',
    component: () => import('@/views/afridia/tableau_de_bord/main/MainVue.vue'),
    meta: {
      pageTitle: 'Tableaux de bord',
      breadcrumb: [
        {
          text: '/',
          active: true,
        },
      ],
    },
  },
  {
    path: '/tableaux-de-bord/appros',
    name: 'tableaux-de-bord-appros',
    component: () => import('@/views/afridia/tableau_de_bord/appros/MainVue.vue'),
    meta: {
      pageTitle: 'Tableaux de bord - Approvisionnements',
      breadcrumb: [
        {
          text: 'Approvisionnements',
          active: true,
        },
      ],
    },
  },
  {
    path: '/tableaux-de-bord/stocks',
    name: 'tableaux-de-bord-stocks',
    component: () => import('@/views/afridia/tableau_de_bord/stocks/MainVue.vue'),
    meta: {
      pageTitle: 'Tableaux de bord - Stocks',
      breadcrumb: [
        {
          text: 'Stocks',
          active: true,
        },
      ],
    },
  },
  {
    path: '/tableaux-de-bord/suivi-stock-distributeurs',
    name: 'tableaux-de-bord-suivi-stock-distributeurs',
    component: () => import('@/views/afridia/tableau_de_bord/suivi-stock-distributeurs/MainVue.vue'),
    meta: {
      pageTitle: 'Tableaux de bord - Suivi stock distributeurs',
      breadcrumb: [
        {
          text: 'Suivi Stock Distributeurs',
          active: true,
        },
      ],
    },
  },
  {
    path: '/tableaux-de-bord/logs',
    name: 'tableaux-de-bord-logs',
    component: () => import('@/views/afridia/tableau_de_bord/logs/MainVue.vue'),
    meta: {
      pageTitle: 'Tableaux de bord - Logs',
      breadcrumb: [
        {
          text: 'Logs',
          active: true,
        },
      ],
    },
  },
  {
    path: '/tableaux-de-bord/commerciaux',
    name: 'tableaux-de-bord-commerciaux',
    component: () => import('@/views/afridia/tableau_de_bord/commerciaux/MainVue.vue'),
    meta: {
      pageTitle: 'Tableaux de bord - Commerciaux',
      breadcrumb: [
        {
          text: 'Commerciaux',
          active: true,
        },
      ],
    },
  },
  {
    path: '/tableaux-de-bord/produits',
    name: 'tableaux-de-bord-produits',
    component: () => import('@/views/afridia/tableau_de_bord/produits/MainVue.vue'),
    meta: {
      pageTitle: 'Tableaux de bord - Produits',
      breadcrumb: [
        {
          text: 'Produits',
          active: true,
        },
      ],
    },
  },
]
