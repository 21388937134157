export default [
  {
    path: '/tourners/liste',
    name: 'tourners-liste',
    component: () => import('@/views/afridia/tourners/liste/MainVue.vue'),
    meta: {
      pageTitle: 'Tournées - Commerciale',
      breadcrumb: [
        {
          text: 'Liste',
          active: true,
        },
      ],
    },
  },
  {
    path: '/tourners/details/:id',
    name: 'tourners-details',
    component: () => import('@/views/afridia/tourners/details/MainVue.vue'),
    meta: {
      pageTitle: 'Tournée - Commerciale',
      breadcrumb: [
        {
          text: 'Infos',
          active: true,
        },
      ],
    },
  },
  {
    path: '/tourners/details/visite/:id',
    name: 'tourners-visite-details',
    component: () => import('@/views/afridia/commerciaux/visite-pdv/details/MainVue.vue'),
    meta: {
      pageTitle: 'Visite',
      breadcrumb: [
        {
          text: 'Details',
          active: true,
        },
      ],
    },
  },
  // {
  //   path: '/tourners/statistiques',
  //   name: 'tourners-statistiques',
  //   component: () => import('@/views/afridia/tourners/statistiques/MainVue.vue'),
  //   meta: {
  //     pageTitle: 'tourners - Statistiques',
  //     breadcrumb: [
  //       {
  //         text: 'Statistiques',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
]
