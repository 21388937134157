export default [
  {
    path: '/imagerie/carte-attentes',
    name: 'imagerie-liste-attentes',
    component: () => import('@/views/pages/imagerie/liste_attente/main.vue'),
    meta: {
      pageTitle: 'Imagerie',
      breadcrumb: [
        {
          text: 'Liste',
        },
        {
          text: 'Attentes',
          active: true,
        },
      ],
    },
  },
  {
    path: '/imagerie/carte-examens',
    name: 'imagerie-liste-examens',
    component: () => import('@/views/pages/imagerie/liste_examen/main.vue'),
    meta: {
      pageTitle: 'Imagerie',
      breadcrumb: [
        {
          text: 'Liste',
        },
        {
          text: 'Examens',
          active: true,
        },
      ],
    },
  },
]
