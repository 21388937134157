export default [
  {
    path: '/consultations/carte-attentes',
    name: 'consultations-liste-attentes',
    component: () => import('@/views/pages/consultations/liste_attente/main.vue'),
    meta: {
      pageTitle: 'Consultations',
      breadcrumb: [
        {
          text: 'Liste',
        },
        {
          text: 'Attentes',
          active: true,
        },
      ],
    },
  },
  {
    path: '/consultations/devis',
    name: 'consultations-statistiques',
    component: () => import('@/views/pages/consultations/statistiques/main.vue'),
    meta: {
      pageTitle: 'Consultations',
      breadcrumb: [
        {
          text: 'Statistiques',
          active: true,
        },
      ],
    },
  },
  {
    path: '/consultations/motifs',
    name: 'consultations-motifs',
    component: () => import('@/views/pages/consultations/parametres/motifs/main.vue'),
    meta: {
      pageTitle: 'Consultations',
      breadcrumb: [
        {
          text: 'Motifs',
          active: true,
        },
      ],
    },
  },
  {
    path: '/consultations/formulaires',
    name: 'consultations-formulaires',
    component: () => import('@/views/pages/consultations/parametres/formulaires/main.vue'),
    meta: {
      pageTitle: 'Consultations',
      breadcrumb: [
        {
          text: 'Formulaires',
          active: true,
        },
      ],
    },
  },
  {
    path: '/consultations/diagnostics',
    name: 'consultations-diagnostics',
    component: () => import('@/views/pages/consultations/parametres/diagnostics/main.vue'),
    meta: {
      pageTitle: 'Consultations',
      breadcrumb: [
        {
          text: 'Diagnostics',
          active: true,
        },
      ],
    },
  },
]
