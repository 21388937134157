export default [
  {
    path: '/equipements/liste',
    name: 'equipements-liste',
    component: () => import('@/views/afridia/equipements/liste/MainVue.vue'),
    meta: {
      pageTitle: 'Équipements - Liste',
      breadcrumb: [
        {
          text: 'Liste',
          active: true,
        },
      ],
    },
  },
  {
    path: '/equipements/statistiques',
    name: 'equipements-statistiques',
    component: () => import('@/views/afridia/equipements/statistiques/MainVue.vue'),
    meta: {
      pageTitle: 'Équipements - Statistiques',
      breadcrumb: [
        {
          text: 'Statistiques',
          active: true,
        },
      ],
    },
  },
]
