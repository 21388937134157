export default [
  {
    path: '/stocks/liste',
    name: 'stocks-liste',
    component: () => import('@/views/afridia/stocks/liste/MainVue.vue'),
    meta: {
      pageTitle: 'Stocks - Liste',
      breadcrumb: [
        {
          text: 'Liste',
          active: true,
        },
      ],
    },
  },
  {
    path: '/stocks/ajustements',
    name: 'stocks-ajustements',
    component: () => import('@/views/afridia/stocks/ajustements/MainVue.vue'),
    meta: {
      pageTitle: 'Stocks - Ajustements',
      breadcrumb: [
        {
          text: 'Ajustements',
          active: true,
        },
      ],
    },
  },
  {
    path: '/stocks/inventaires',
    name: 'stocks-inventaires',
    component: () => import('@/views/afridia/stocks/inventaires/MainVue.vue'),
    meta: {
      pageTitle: 'Stocks - Inventaires',
      breadcrumb: [
        {
          text: 'Inventaires',
          active: true,
        },
      ],
    },
  },
  {
    path: '/stocks/statistiques',
    name: 'stocks-statistiques',
    component: () => import('@/views/afridia/stocks/statistiques/MainVue.vue'),
    meta: {
      pageTitle: 'Stocks - Statistiques',
      breadcrumb: [
        {
          text: 'Statistiques',
          active: true,
        },
      ],
    },
  },
]
