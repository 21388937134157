// export const BASE_URL = 'http://127.0.0.1:8000'
export const BASE_URL = 'https://api-giwu.afridiafdv.industries'

export const API_URL = `${BASE_URL}/api/afridia/`
export const HIDDEN_NAVBAR = false
export const HIDDEN_MENU = false
export const COLOR_PRIMARY = '#1E90FF'
export const OVERLAY_CONFIG = {
  variant: 'transparent',
  opacity: 0.90,
  blur: '2px',
  rounded: 'sm',
}
