// eslint-disable-next-line import/extensions
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import useJwt from '@/auth/jwt/useJwt'
import { initialAbility } from '@/libs/acl/config'

export default function showToast(that, variant, title, text) {
  that.$toast({
    component: ToastificationContent,
    props: {
      title,
      icon: 'CheckIcon',
      text,
      variant,
    },
  })
}

export function restObject(object, customValues = {}, excepts = {}) {
  Object.keys(object)
    .forEach(key => {
      // console.log(key, object[key])
      if (!excepts[key]) {
        // eslint-disable-next-line no-param-reassign
        object[key] = null
      }
      if (customValues[key]) {
        // eslint-disable-next-line no-param-reassign
        object[key] = customValues[key]
      }
    })
}

export function searchInObject(object, attribute, value) {
  let objectFind = null
  Object.keys(object)
    .forEach(key => {
      console.log(key, object[key])
      if (key === attribute && object[key] === value) {
        // eslint-disable-next-line no-param-reassign
        objectFind = object[key]
      }
    })
  return objectFind
}

export function firstDayOfMonth() {
  const date = new Date()
  return new Date(date.getFullYear(), date.getMonth(), 1)
}

export function dateFormater(date) {
  if (date instanceof Date) {
    return date.toLocaleString('fr-FR', {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
      // hour: 'numeric',
      // minute: 'numeric',
      // second: 'numeric',
    })
  }
  return new Date(date).toLocaleString('fr-FR', {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
    // hour: 'numeric',
    // minute: 'numeric',
    // second: 'numeric',
  })
}

export function nowDate() {
  const date = new Date()
  return new Date(date.getFullYear(), date.getMonth(), date.getDate())
}

export function formatDate(date) {
  if (!date) {
    return null
  }
  const day = date.getDate().toString().padStart(2, '0')
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const year = date.getFullYear().toString()
  return `${day}/${month}/${year}`
}

export function myLogout(that) {
  // Remove userData from localStorage
  // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
  localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
  localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

  // Remove userData from localStorage
  localStorage.removeItem('userData')

  // Reset ability
  that.$ability.update(initialAbility)

  // Redirect to login page
  that.$router.push({ name: 'login' })
}

export function getWeekRange(year) {
  const startDate = new Date(year, 0, 1) // Premier janvier de l'année donnée
  const endDate = new Date(year, 11, 31) // Dernier décembre de l'année donnée

  const result = []

  // Boucle à travers toutes les dates de l'année
  // Boucle à travers toutes les dates de l'année
  while (startDate <= endDate) {
    const weekNumber = getISOWeek(startDate) // Obtient le numéro de semaine ISO
    const startMonth = startDate.toLocaleString('default', { month: 'short' })
    const endWeekDate = new Date(startDate)
    endWeekDate.setDate(endWeekDate.getDate() + 6)
    const endMonth = endWeekDate.toLocaleString('default', { month: 'short' })

    const weekRange = `SEMAINE${weekNumber} DU ${startMonth} ${startDate.getDate()} AU ${endMonth} ${endWeekDate.getDate()}`
    result.push({
      weekNumber,
      weekRange,
      // startDate: new Date(startDate),
      // endDate: new Date(endWeekDate),
    })

    startDate.setDate(startDate.getDate() + 7) // Passe à la semaine suivante
  }
  return result
}

// Fonction pour obtenir le numéro de semaine ISO
function getISOWeek(date) {
  const jan1 = new Date(date.getFullYear(), 0, 1)
  return Math.ceil((((date - jan1) / 86400000) + jan1.getDay() + 1) / 7)
}

export function getFirstDayOfCurrentMonth() {
  const date = new Date()
  const year = date.getFullYear()
  const month = (1 + date.getMonth()).toString().padStart(2, '0')
  return `${year}-${month}-01`
}
export function getCurrentDate() {
  const date = new Date()
  const year = date.getFullYear()
  const month = (1 + date.getMonth()).toString().padStart(2, '0')
  const day = date.getDate().toString().padStart(2, '0')
  return `${year}-${month}-${day}`
}
export function getBefore3day() {
  const date = new Date()
  date.setDate(date.getDate() - 3)
  const year = date.getFullYear()
  const month = (1 + date.getMonth()).toString().padStart(2, '0')
  const day = date.getDate().toString().padStart(2, '0')
  return `${year}-${month}-${day}`
}

export function premiereDateDeLaSemaine() {
  const aujourdHui = new Date()
  const jour = aujourdHui.getDay()
  const difference = (jour === 0) ? -6 : 1 - jour // Si c'est dimanche, nous soustrayons 6 jours, sinon nous soustrayons le jour courant moins 1
  const lundi = new Date(aujourdHui.setDate(aujourdHui.getDate() + difference))

  // Renvoyer la date sous format 'AAAA-MM-JJ'
  return `${lundi.getFullYear()}-${String(lundi.getMonth() + 1).padStart(2, '0')}-${String(lundi.getDate()).padStart(2, '0')}`
}

// function getISOWeek(date = new Date()) {
//   const januaryFirst = new Date(date.getFullYear(), 0, 1);
//   const dayOfWeek = januaryFirst.getDay() || 7;
//   januaryFirst.setDate(januaryFirst.getDate() + 4 - dayOfWeek);
//   return Math.ceil((((date - januaryFirst) / 86400000) + 1) / 7);
// }

export function getCurrentWeekRange() {
  const currentDate = new Date()
  const currentYear = currentDate.getFullYear()
  const currentWeekNumber = getISOWeek(currentDate)

  const startOfWeek = new Date(currentDate)
  startOfWeek.setDate(currentDate.getDate() - currentDate.getDay() + 1)
  const endOfWeek = new Date(startOfWeek)
  endOfWeek.setDate(endOfWeek.getDate() + 6)

  const startMonth = startOfWeek.toLocaleString('default', { month: 'short' })
  const endMonth = endOfWeek.toLocaleString('default', { month: 'short' })

  const weekRange = `SEMAINE ${currentWeekNumber} DU ${startMonth} ${startOfWeek.getDate()} AU ${endMonth} ${endOfWeek.getDate()}`

  return {
    weekNumber: currentWeekNumber,
    weekRange,
  }
}
export function generateConcatenatedWord(word) {
  const currentDate = new Date()
  const day = currentDate.getDate().toString().padStart(2, '0')
  const month = (currentDate.getMonth() + 1).toString().padStart(2, '0') // Les mois commencent à 0 en JavaScript
  const year = currentDate.getFullYear()
  const hours = currentDate.getHours().toString().padStart(2, '0')
  const minutes = currentDate.getMinutes().toString().padStart(2, '0')

  return `${word}_${day}_${month}_${year}_${hours}H_${minutes}`
}
