export default [
  {
    path: '/parametres/canaux-distribution',
    name: 'parametres-canaux-distribution',
    component: () => import('@/views/afridia/parametres/canals/MainVue.vue'),
    meta: {
      pageTitle: 'Paramètres - Canaux de distribution',
      breadcrumb: [
        {
          text: 'Configurations/Canaux de distribution',
          active: true,
        },
      ],
    },
  },
  {
    path: '/parametres/produits-afridia',
    name: 'parametres-produits-afridia',
    component: () => import('@/views/afridia/parametres/produits_afridia/MainVue.vue'),
    meta: {
      pageTitle: 'Paramètres - Produits Afridia',
      breadcrumb: [
        {
          text: 'Configurations/Produits Afridia',
          active: true,
        },
      ],
    },
  },
  {
    path: '/parametres/produits-concurrence',
    name: 'parametres-produits-concurrence',
    component: () => import('@/views/afridia/parametres/produits_concurrence/MainVue.vue'),
    meta: {
      pageTitle: 'Paramètres - Produits Concurrence',
      breadcrumb: [
        {
          text: 'Configurations/Produits Concurrence',
          active: true,
        },
      ],
    },
  },
  {
    path: '/parametres/produits-type',
    name: 'parametres-produits-type',
    component: () => import('@/views/afridia/parametres/produit_type/MainVue.vue'),
    meta: {
      pageTitle: 'Paramètres - Produits Type',
      breadcrumb: [
        {
          text: 'Configurations/Produits Type',
          active: true,
        },
      ],
    },
  },
  {
    path: '/parametres/produit-saveurs',
    name: 'parametres-produit-saveurs',
    component: () => import('@/views/afridia/parametres/produit_saveurs/MainVue.vue'),
    meta: {
      pageTitle: 'Paramètres - Produit Saveurs',
      breadcrumb: [
        {
          text: 'Configurations/Produits Saveurs',
          active: true,
        },
      ],
    },
  },
  {
    path: '/parametres/permissions-pages',
    name: 'parametres-permissions-pages',
    component: () => import('@/views/afridia/parametres/permissions/pages/MainVue.vue'),
    meta: {
      pageTitle: 'Paramètres - Permissions Pages',
      breadcrumb: [
        {
          text: 'Configurations/Permissions Pages',
          active: true,
        },
      ],
    },
  },
  {
    path: '/parametres/produits-parfum',
    name: 'parametres-produits-parfum',
    component: () => import('@/views/afridia/parametres/parfum_produit/MainVue.vue'),
    meta: {
      pageTitle: 'Paramètres - Produits Parfum',
      breadcrumb: [
        {
          text: 'Configurations/Produits Parfum',
          active: true,
        },
      ],
    },
  },
  {
    path: '/parametres/pdv-type',
    name: 'parametres-pdv-type',
    component: () => import('@/views/afridia/parametres/type_pdv/MainVue.vue'),
    meta: {
      pageTitle: 'Paramètres - PDV Type',
      breadcrumb: [
        {
          text: 'Configurations/PDV Type',
          active: true,
        },
      ],
    },
  },
  {
    path: '/parametres/actions',
    name: 'parametres-actions',
    component: () => import('@/views/afridia/parametres/actions/MainVue.vue'),
    meta: {
      pageTitle: 'Paramètres - Visites Actions',
      breadcrumb: [
        {
          text: 'Configurations/PDV Actions',
          active: true,
        },
      ],
    },
  },
  {
    path: '/parametres/arrondissement',
    name: 'parametres-arrondissement',
    component: () => import('@/views/afridia/parametres/arrondissements/MainVue.vue'),
    meta: {
      pageTitle: 'Paramètres - Arrondissement',
      breadcrumb: [
        {
          text: 'Configurations/Arrondissement',
          active: true,
        },
      ],
    },
  },
  {
    path: '/parametres/configurations',
    name: 'parametres-configurations',
    component: () => import('@/views/afridia/parametres/configurations/MainVue.vue'),
    meta: {
      pageTitle: 'Paramètres - Configurations',
      breadcrumb: [
        {
          text: 'Configurations',
          active: true,
        },
      ],
    },
  },
  {
    path: '/parametres/permissions/:id',
    name: 'parametres-permissions',
    component: () => import('@/views/afridia/parametres/permissions/MainVue.vue'),
    meta: {
      pageTitle: 'Paramètres - Permissions',
      breadcrumb: [
        {
          text: 'Permissions',
          active: true,
        },
      ],
    },
  },
  {
    path: '/parametres/profile',
    name: 'parametres-profiles',
    component: () => import('@/views/afridia/parametres/profils/MainVue.vue'),
    meta: {
      pageTitle: 'Paramètres - Profile',
      breadcrumb: [
        {
          text: 'Configurations/Profiles',
          active: true,
        },
      ],
    },
  },
  {
    path: '/parametres/profile-perso',
    name: 'parametres-profile',
    component: () => import('@/views/afridia/profile/Profile.vue'),
    meta: {
      pageTitle: 'Profile',
      breadcrumb: [
        {
          text: 'Configurations/Profile',
          active: true,
        },
      ],
    },
  },
  {
    path: '/parametres/utilisateurs',
    name: 'parametres-utilisateurs',
    component: () => import('@/views/afridia/parametres/utilisateurs/MainVue.vue'),
    meta: {
      pageTitle: 'Paramètres - Utilisateurs',
      breadcrumb: [
        {
          text: 'Utilisateurs',
          active: true,
        },
      ],
    },
  },
]
