import useJwt from '@/auth/jwt/useJwt'

/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */
// eslint-disable-next-line arrow-body-style
export const isUserLoggedIn = () => {
  return localStorage.getItem('userData') && localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName)
}

export const getUserData = () => JSON.parse(localStorage.getItem('userData'))
export const getUserPermissions = () => (getUserData() ? getUserData().permissions : null)

/**
 * This function is used for demo purpose route navigation
 * In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 * Please note role field is just for showing purpose it's not used by anything in frontend
 * We are checking role just for ease
 * NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
  if (userRole === 'Administrateur') return '/'
  if (userRole === 'Responsable commercial') return '/'
  if (userRole === 'Commercial') return '/'
  if (userRole === 'client') return { name: 'access-control' }
  return { name: 'auth-login' }
}

export function getPermissionByPageTitle(route) {
  const permissions = getUserPermissions()
  // console.log(permissions.find(permission => permission.page.route === permissions[5].page.route), route)
  if (permissions) {
    return permissions.find(permission => permission.page.route === route)
  }
  return null
}

export function filterAppPages(appPages) {
  const response = []
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < appPages.length; i++) {
    const object = getPermissionByPageTitle(appPages[i].name)
    if (object) {
      if (object.read) {
        response.push(appPages[i])
      }
    }
  }
  // Filtrer les appPages selon les noms des routes autorisées
  console.log(response)
  return response
}

export function findPagePermissions(pageName, permissions) {
  const permissionsFilter = permissions.find(permission => permission.page.route === pageName)
  console.log(permissionsFilter)

  return {
    all: permissionsFilter.all,
    read: permissionsFilter.read,
    write: permissionsFilter.write,
  }
}
