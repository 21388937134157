export default [
  {
    path: '/dossiers-patients/carte',
    name: 'dossiers-patients-liste',
    component: () => import('@/views/pages/dossiers_patients/liste/main.vue'),
    meta: {
      pageTitle: 'Dossiers patients',
      breadcrumb: [
        {
          text: 'Liste',
        },
        {
          text: 'Dossiers',
          active: true,
        },
      ],
    },
  },
  {
    path: '/dossiers-patients/devis',
    name: 'dossiers-patients-statistiques',
    component: () => import('@/views/pages/dossiers_patients/statistiques/main.vue'),
    meta: {
      pageTitle: 'Dossiers patients',
      breadcrumb: [
        {
          text: 'Statistiques',
          active: true,
        },
      ],
    },
  },
  {
    path: '/dossiers-patients/patient/:id',
    name: 'dossiers-patients-details',
    component: () => import('@/views/pages/dossiers_patients/details/main.vue'),
    meta: {
      pageTitle: 'Dossiers patient',
      breadcrumb: [
        {
          text: 'Dossier',
          active: true,
        },
      ],
    },
  },

]
