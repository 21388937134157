export default [
  {
    path: '/dashboard/analytics',
    name: 'dashboard-analytics',
    component: () => import('@/views/dashboard/analytics/Analytics.vue'),
  },
  {
    path: '/dashboard/devis',
    name: 'dashboard-statistiques',
    component: () => import('@/views/pages/dashboards/statistiques.vue'),
  },
]
