export default [
  {
    path: '/ventes/liste',
    name: 'ventes-liste',
    component: () => import('@/views/afridia/ventes/liste/MainVue.vue'),
    meta: {
      pageTitle: 'Ventes - Liste',
      breadcrumb: [
        {
          text: 'Liste',
          active: true,
        },
      ],
    },
  },
  {
    path: '/ventes/devis',
    name: 'ventes-devis',
    component: () => import('@/views/afridia/ventes/devis/MainVue.vue'),
    meta: {
      pageTitle: 'Ventes - Devis',
      breadcrumb: [
        {
          text: 'Devis',
          active: true,
        },
      ],
    },
  },
  {
    path: '/ventes/factures',
    name: 'ventes-factures',
    component: () => import('@/views/afridia/ventes/factures/MainVue.vue'),
    meta: {
      pageTitle: 'Ventes - Factures',
      breadcrumb: [
        {
          text: 'Factures',
          active: true,
        },
      ],
    },
  },
]
