import { BASE_URL } from '@/helpers/global-scops'

export default {
  // Endpoints
  // loginEndpoint: '/jwt/login',
  loginEndpoint: `${BASE_URL}/api/token/`,
  registerEndpoint: '/jwt/register',
  // refreshEndpoint: '/jwt/refresh-token',
  refreshEndpoint: `${BASE_URL}/api/token/refresh/`,
  logoutEndpoint: '/jwt/logout',

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'access',
  storageRefreshTokenKeyName: 'refresh',
}
