import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import {
  isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser, getPermissionByPageTitle,
} from '@/auth/utils'

import tableauxDeBord from '@/router/routes/afridia/tableaux_de_bord'
// import commerciaux from '@/router/routes/afridia/commerciaux'
import ventes from '@/router/routes/afridia/ventes'
import stocks from '@/router/routes/afridia/stocks'
import equipements from '@/router/routes/afridia/equipements'
import zones from '@/router/routes/afridia/zones'

import tourners from './routes/afridia/tourners'
import regions from './routes/afridia/regions'
import points_de_vente from './routes/afridia/points_de_vente'
import geolocalisation from './routes/afridia/geolocalisation'
import distributeurs from './routes/afridia/distributeurs'
import parametres from './routes/afridia/parametres'
import apps from './routes/apps'
import dashboard from './routes/dashboard'
import uiElements from './routes/ui-elements/index'
import themePages from './routes/theme-pages'
import chartsMaps from './routes/charts-maps'
import formsTable from './routes/forms-tables'
import others from './routes/others'
import pages from './routes/pages'
import caisse from './routes/caisse'
import consultations from './routes/consultations'
import dossierPatients from './routes/dossier_patients'
import pharmacie from './routes/pharmacie'
import laboratoire from './routes/laboratoire'
import imagerie from './routes/imagerie'
import commerciaux from './routes/afridia/commerciaux'

Vue.use(VueRouter)
const router = new VueRouter({
  // mode: 'history',
  linkActiveClass: 'active',
  linkExactActiveClass: '',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'tableaux-de-bord-ventes' } },
    ...pages,
    ...apps,
    ...dashboard,
    ...themePages,
    ...chartsMaps,
    ...formsTable,
    ...uiElements,
    ...others,
    ...caisse,
    ...consultations,
    ...dossierPatients,
    ...pharmacie,
    ...laboratoire,
    ...imagerie,
    ...tableauxDeBord,
    ...commerciaux,
    ...points_de_vente,
    ...ventes,
    ...stocks,
    ...equipements,
    ...geolocalisation,
    ...parametres,
    ...zones,
    ...regions,
    ...tourners,
    ...distributeurs,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()

  console.log('to', to)
  const object = getPermissionByPageTitle(to.name)
  // console.log('object', object, object && !object.read && to.name !== 'misc-not-authorized')
  if (object && !object.read && to.name !== 'misc-not-authorized') {
    return next({ name: 'misc-not-authorized' })
  }
  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login' })

    // If logged in => not authorized
    return next({ name: 'misc-not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
