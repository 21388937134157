export default [
  {
    path: '/zones/liste',
    name: 'zones-liste',
    component: () => import('@/views/afridia/zones/liste/MainVue.vue'),
    meta: {
      pageTitle: 'Liste des Zones',
      breadcrumb: [
        {
          text: 'Zones/Liste',
          active: true,
        },
      ],
    },
  },
  {
    path: '/zones/details/:id',
    name: 'zones-details',
    component: () => import('@/views/afridia/zones/details/MainVue.vue'),
    meta: {
      pageTitle: 'Zone',
      breadcrumb: [
        {
          text: 'Infos',
          active: true,
        },
      ],
    },
  },

  // {
  //   path: '/zones/objectifs',
  //   name: 'zones-objectifs',
  //   component: () => import('@/views/afridia/zones/objectifs/MainVue.vue'),
  //   meta: {
  //     pageTitle: 'zones - Objectifs',
  //     breadcrumb: [
  //       {
  //         text: 'Objectifs',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: '/zones/statistiques',
  //   name: 'zones-statistiques',
  //   component: () => import('@/views/afridia/zones/statistiques/MainVue.vue'),
  //   meta: {
  //     pageTitle: 'zones - Statistiques',
  //     breadcrumb: [
  //       {
  //         text: 'Statistiques',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
]
