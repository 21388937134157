export default [
  {
    path: '/caisse/ticket',
    name: 'caisse-ticket',
    component: () => import('@/views/pages/caisse/ticket/main.vue'),
    meta: {
      pageTitle: 'Caisse',
      breadcrumb: [
        {
          text: 'Ticket',
        },
        {
          text: 'Nouveau',
          active: true,
        },
      ],
    },
  },
  {
    path: '/caisse/tickets-carte',
    name: 'caisse-tickets-liste',
    component: () => import('@/views/pages/caisse/tickets_liste/main.vue'),
    meta: {
      pageTitle: 'Caisse',
      breadcrumb: [
        {
          text: 'Tickets',
        },
        {
          text: 'carte',
          active: true,
        },
      ],
    },
  },
  {
    path: '/caisse/tickets-vendus',
    name: 'caisse-tickets-vendus',
    component: () => import('@/views/pages/caisse/tickets_vendus/main.vue'),
    meta: {
      pageTitle: 'Caisse',
      breadcrumb: [
        {
          text: 'Tickets',
        },
        {
          text: 'Vendus',
          active: true,
        },
      ],
    },
  },
  {
    path: '/caisse/devis',
    name: 'caisse-statistiques',
    component: () => import('@/views/pages/caisse/statistiques/main.vue'),
    meta: {
      pageTitle: 'Caisse',
      breadcrumb: [
        {
          text: 'Statistiques',
          active: true,
        },
      ],
    },
  },
  {
    path: '/caisse/ticket/preview/:id',
    name: 'caisse-ticket-preview',
    component: () => import('@/views/pages/caisse/print/TicketPreview.vue'),
  },
]
