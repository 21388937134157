export default [
  {
    path: '/regions/liste',
    name: 'regions-liste',
    component: () => import('@/views/afridia/regions/liste/MainVue.vue'),
    meta: {
      pageTitle: 'Liste des regions',
      breadcrumb: [
        {
          text: 'regions/Liste',
          active: true,
        },
      ],
    },
  },
  {
    path: '/regions/details/:id',
    name: 'regions-details',
    component: () => import('@/views/afridia/regions/details/MainVue.vue'),
    meta: {
      pageTitle: 'Region details',
      breadcrumb: [
        {
          text: 'Infos',
          active: true,
        },
      ],
    },
  },

  // {
  //   path: '/regions/objectifs',
  //   name: 'regions-objectifs',
  //   component: () => import('@/views/afridia/regions/objectifs/MainVue.vue'),
  //   meta: {
  //     pageTitle: 'regions - Objectifs',
  //     breadcrumb: [
  //       {
  //         text: 'Objectifs',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: '/regions/statistiques',
  //   name: 'regions-statistiques',
  //   component: () => import('@/views/afridia/regions/statistiques/MainVue.vue'),
  //   meta: {
  //     pageTitle: 'regions - Statistiques',
  //     breadcrumb: [
  //       {
  //         text: 'Statistiques',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
]
